<template>
    <st-section
        :header="$t('URBANISM_REGULATIONS_EDIT.LIST_HEADER')"
        customClass="list-section"
        id="urbanism-regulations"
        :validate="!viewMode"
        :wasValidated="wasValidated"
        :validation="validationStatus"
    >
        <template #body>
            <carousel-3d
                :controls-visible="true"
                :width="400"
                :height="220"
                :space="300"
                :count="itemsComputed.length"
                dispay="3"
                ref="urbanismRegulationsCarousel"
            >
                <slide v-for="(item, i) in itemsComputed" :index="i" :key="i">
                    <div class="carousel-card real-estate-card">
                        <urbanism-regulations-item-card
                            v-if="!item.isPlaceholder"
                            @edit="onEdit"
                            @view="onView"
                            @delete="onDelete"
                            :item="item"
                            :index="i"
                            :viewMode="viewMode"
                            :humanReview="humanReview"
                            :canEditStaff="canEditStaff"
                            :canReviewStaff="canReviewStaff"
                        ></urbanism-regulations-item-card>
                        <div class="card-user-placeholder" v-else>
                            <i class="fas fa-home"></i>
                        </div>
                    </div>
                </slide>
            </carousel-3d>

            <div class="d-flex list-footer">
                <div class="col-9 d-flex align-items-center px-0">
                    <span>{{ total }}</span>
                </div>
                <div
                    class="col-3 d-flex justify-content-end px-0"
                    v-if="!viewMode"
                >
                    <st-button :callback="openUrbanismRegulationsModal" variant="link">
                        <span class="d-flex align-items-center">
                            <i class="fas fa-plus"></i>
                            {{ $t("URBANISM_REGULATIONS_EDIT.FORM.ADD_BTN") }}
                        </span>
                    </st-button>
                </div>
            </div>
            <urbanism-regulations-modal
                ref="urbanismRegulationsModal"
                :item="selectedEntity"
                :canEditStaff="canEditStaff"
                :canReviewStaff="canReviewStaff"
                :ruScope="ruScope"
                @processUrbanismRegulations="processUrbanismRegulations"
                @cancel="cancel"
            ></urbanism-regulations-modal>
        </template>
    </st-section>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { Carousel3d, Slide } from "vue-carousel-3d";
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";
import { UrbanismRegulationsModel } from "@/modules/applications/models/urbanism-regulations-model";
import UrbanismRegulationsModal from '@/modules/applications/components/urbanism-regulations-edit/UrbanismRegulationsModal.vue';
import UrbanismRegulationsItemCard from '@/modules/applications/components/urbanism-regulations-edit/UrbanismRegulationsItemCard.vue';

const { fields } = UrbanismRegulationsModel;

export default {
    name: "UrbanismRegulationsList",
    components: {
        UrbanismRegulationsModal,
        UrbanismRegulationsItemCard,
        Carousel3d,
        Slide,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        wasValidated: {
            type: Boolean,
            default: false,
        },
        humanReview: {
            type: Boolean,
            default: false,
        },
        partialEdit: {
            type: Boolean,
            default: false,
        },
        // delete after CF is merged
        selectedUtrDocumentTemplate: {
            type: String,
            default: '',
        },
        ruScope: {
            type: String,
            require: true,
        },
    },
    data() {
        return {
            items: [],
            selectedEntity: null,
            selectedEntityIndex: null,
        };
    },
    watch: {
        record: {
            deep: true,
            immediate: true,
            handler(value) {
                this.items = value?.form?.urbanism_regulations ?? value?.form_staff?.urbanism_regulations ?? [];
                this.$emit("changeList", this.items, "urbanism_regulations", true);
            },
        },
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            currentUser: 'auth/currentUser',
            isStaff: "auth/isStaff",
        }),
        fields() {
            fields.address.tdClass = "d-flex col-md-6 align-items-center";
            return [
                fields.address,
            ];
        },
        total() {
            return this.items?.length === 1
                ? this.$t("URBANISM_REGULATIONS_EDIT.SINGLE", { total: 1 })
                : this.$t("URBANISM_REGULATIONS_EDIT.MULTIPLE", { total: this.items.length });
        },
        itemsComputed() {
            const countItems = this.items?.length;
            if (!countItems >= 3) return [...this.items];
            const previewItems = 3;
            let visibleItems = [];
            if (countItems > 0) {
                visibleItems = [...this.items];
            }
            const placeholderItems = previewItems - countItems;
            for (let i = 0; i < placeholderItems; i += 1) {
                visibleItems.push({
                    isPlaceholder: true,
                });
            }

            return visibleItems;
        },
        validationStatus() {
            return this.items?.length ? "success" : "error";
        },
        canReviewStaff() {
            return this.isStaff
                && this.humanReview
                && this.record?.assigned_staff_user_id === this.currentUser.user_id
        },
        canEditStaff() {
            return this.isStaff
                && (this.partialEdit || this.humanReview)
                && this.record?.assigned_staff_user_id === this.currentUser.user_id
                && (this.record?.internal_status === "pending" || this.record?.internal_status === "review" || this.humanReview)
        },
        hasPermissionToReadStaff() {
            return new ApplicationsPermissions(this.currentUser).read;
        },
    },
    methods: {
        ...mapActions({
            doUpdate: "applications/form/update",
        }),
        openUrbanismRegulationsModal() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
            this.$refs.urbanismRegulationsModal.show();
        },
        async processUrbanismRegulations(data) {
            const isEdit = Object.keys(data).some((el) => el === "index");
            if (isEdit) {
                Vue.set(this.items, data.index, data.urbanismRegulationsDetails);
            } else {
                this.items.push({
                    ...data.urbanismRegulationsDetails,
                    index: this.items.length,
                });
                this.goToSlide(this.items.length - 1);
            }

            this.$emit("changeList", this.items, "urbanism_regulations", true);
            this.$emit("changeList", this.selectedUtrDocumentTemplate, "urbanism_regulations_utr_template_id"); // delete after CF is merged

            if(this.record?.id) {
                const updatedValues = {
                    urbanism_regulations: this.items,
                    urbanism_regulations_utr_template_id: this.selectedUtrDocumentTemplate,  // delete after CF is merged
                }

                let formValues = {
                    form: {
                        ...this.record.form,
                        ...updatedValues,
                    },
                }

                if(this.isStaff) {
                    formValues = {
                        ...formValues,
                        form_staff: {
                            ...this.record.form_staff,
                            ...updatedValues,
                        },
                    }
                }

                await this.doUpdate({
                    id: this.record.id,
                    values: formValues,
                    loadStaff: this.isStaff ?? false,
                });
            }

            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
        onEdit({ item, index }) {
            this.selectedEntity = item;
            this.selectedEntity.index = index;
            this.$refs.urbanismRegulationsModal.show();
        },
        onDelete({ index }) {
            this.items.splice(index, 1);
            this.goToSlide(0);
        },
        onView({ item, index }) {
            this.selectedEntity = item;
            this.selectedEntity.index = index;
            const viewMode = true;
            this.$refs.urbanismRegulationsModal.show(viewMode);
        },
        cancel() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
        goToSlide(index) {
            this.$refs.urbanismRegulationsCarousel.goSlide(index);
        },
    },
    created() {
        this.items = [];
        if (this.$route.params.id) {
            this.items = this.record?.form?.urbanism_regulations ?? [];
        }
    },
};
</script>
